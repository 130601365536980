<template>
<div class="bg-white p-2 rounded-md mt-2">
  <DataTable :value="listProcesosJudiciales.rows"
             class="p-datatable-customers p-datatable-sm text-sm"
             showGridlines
             dataKey="id"
             v-model:filters="filters1"
             filterDisplay="menu"
             responsiveLayout="scroll"
             @sort="sort"
             removableSort
  >
    <template #header>
      <div class="flex justify-end">
        <div class="flex">
          <Button type="button"
                  icon="pi pi-filter-slash text-sm"
                  label="Limpiar filtros"
                  class="p-button-outlined text-sm font-medium"
                  @click="clearFilter1()"
          />
        </div>
      </div>
    </template>
    <template #empty>
      No hay datos para mostrar
    </template>

    <Column headerStyle="width: 3rem" style="text-align: center; justify-content: center;" field="id" header="Id" class="text-xs">
      <template #body="{data}">
        <Button :label="`${data.id}`"
                class="p-button-link text-black"
                style="font-size: 0.75rem"
                @click="$router.push({name:'pharmasan.administrativa.juridica.procesos-judiciales.ver', params: { id: data.id }})" />
      </template>
    </Column>

    <Column field="fechaReparto" header="Fecha Reparto" style="min-width:8rem" class="text-xs">
      <template #body="{data}">
        {{ $h.formatDate(data.fechaReparto) }}
      </template>
    </Column>

    <Column field="fechaNotificacion" header="Fecha Notificacion" style="min-width:8rem" class="text-xs">
      <template #body="{data}">
        {{ $h.formatDate(data.fechaNotificacion) }}
      </template>
    </Column>

    <Column field="numeroRadicado" header="# Radicado" style="min-width:14rem" class="text-xs">
      <template #body="{data}">
        {{ data.numeroRadicado.slice(0,16) }}
        <span class="font-bold">{{ data.numeroRadicado.slice(16,20) }}</span>
        -
        <span class="font-bold">{{ data.numeroRadicado.slice(21,26) }}</span>
        {{ data.numeroRadicado.slice(26,29) }}
      </template>
    </Column>

    <Column header="Juzgado" style="min-width:10rem" class="text-xs">
      <template #body="{data}">
        {{ data.juzgadoProcesosJudiciales.nombre }}
      </template>
    </Column>

    <Column header="Responsable" style="min-width:10rem" class="text-xs">
      <template #body="{data}">
        {{ data.responsableProcesosJudiciales.fullName }}
      </template>
    </Column>

    <Column field="medidacautelar" header="Medida Cautelar" style="width:5rem" class="text-xs">
      <template #body="{data}">
        {{ data.medidaCautelar ? 'Si' : 'No' }}
      </template>
    </Column>

    <Column header="Tipo Proceso" style="min-width:10rem" class="text-xs">
      <template #body="{data}">
        {{ data.claseAssociated.tipoAssociated.nombre }}, {{ data.claseAssociated.nombre }}
      </template>
    </Column>

<!--    <Column header="Estado" style="min-width:10rem" class="text-xs">-->
<!--&lt;!&ndash;      <template #body="{data}">&ndash;&gt;-->
<!--&lt;!&ndash;        {{ data.responsableName }}&ndash;&gt;-->
<!--&lt;!&ndash;      </template>&ndash;&gt;-->
<!--      <template #body>-->
<!--        No tiene-->
<!--      </template>-->
<!--    </Column>-->

<!--    <Column header="Acciones">-->
<!--      <template #body="{data}">-->
<!--        <div class="flex gap-4 justify-center" style="text-align: center; justify-content: center;">-->
<!--          <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning" @click="onEditar(data)" />-->
<!--          &lt;!&ndash; <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="remove(data)" /> &ndash;&gt;-->
<!--        </div>-->
<!--      </template>-->
<!--    </Column>-->
  </DataTable>
  <Paginator v-model:first="params.offset"
             :rows="params.limit"
             :totalRecords="listProcesosJudiciales.count"
             :rowsPerPageOptions="[2,10,20,30]"
             @page="onPage($event)"
  />
</div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'
import ProcesosJudicialesStore from '../../../../../store/procesosJudiciales.store'

export default {
  name: 'TablaProcesosJuridicos',
  setup () {
    const router = useRouter()
    const offset = ref(0)
    const limit = ref(10)
    const page = ref(0)
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value,
        filters: filters1.value
      }
    })
    const listProcesosJudiciales = computed(() => ProcesosJudicialesStore.getters.getListProcesosJudiciales)

    const filters1 = ref({
      fullname: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      docTipoId: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      numeroIdentificacion: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      telefono: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      }
    })

    const clearFilter1 = () => {
      filters1.value = {
        fullname: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        docTipoId: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        numeroIdentificacion: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        telefono: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        }
      }
    }

    const onEditar = (data) => {
      router.push({ name: 'procesos.judiciales.procesosjudiciales.editar', params: { id: data.id } })
    }

    const onPage = ({ first, page, pageCount, rows }) => {
      params.value.offset = first
      params.value.page = page
      ProcesosJudicialesStore.dispatch('storeGetSearch', params.value)
    }

    const remove = ({ id }) => {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Si se elimina este juzgado no se puede recuperar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
        }
      })
    }

    const sort = (evt) => {
      console.log({ evt })
    }

    watch(filters1, (a, b) => {
      offset.value = 0
      page.value = 0
      limit.value = 10
      ProcesosJudicialesStore.dispatch('storeGetSearch', params.value)
    })

    onMounted(() => {
      ProcesosJudicialesStore.dispatch('storeGetSearch', params.value)
    })

    return {
      filters1,
      params,
      clearFilter1,
      onPage,
      remove,
      onEditar,
      listProcesosJudiciales,
      sort
    }
  }
}
</script>
<style>
</style>
